@import '~@nc-gb-sds/global/index';
@import '~@nc-gb-sds/typography/index';

html,
.pp-hero-animation-layer__1,
.pp-hero-circle {
  background-color: gb-get-color(gray-90);
}
footer {
  background-color: gb-get-color(gray-80);
}
.pp-hero__right {
  background-color: gb-get-color(brand-80);
}
.pp-hero-eclipse {
  box-shadow: 0 0 20px 10px gb-get-color(lightblue-50), 0 0 10px 10px gb-get-color(lightblue-50), 0 0 50px 25px gb-get-color(lightblue-50), 0 0 80px 75px gb-get-color(brand-80), inset 0 0 100px 75px gb-get-color(lightblue-50), inset 0 0 100px 250px gb-get-color(turquoise-50);
}