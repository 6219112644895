@import '~@nc-gb-sds/collapse/index';
@import '~@nc-gb-sds/accordion/index';

@import "~@nc-gb-sds/accordion/mixins";
@import "~@nc-gb-sds/theme/index";

#{$gb-scope} {
  .gb-accordion-custom {
    @include gb-accordion-icon-color(
      gb-get-color(gray-5),
      gb-get-color(gray-5)
    );
    @include gb-accordion-title-color(
      gb-get-color(gray-5),
      gb-get-color(gray-5)
    );
  }
  .gb-accordion-custom:hover {
    @include gb-accordion-icon-color(
      gb-get-color(gray-40),
      gb-get-color(gray-40)
    );
    @include gb-accordion-title-color(
      gb-get-color(gray-40),
      gb-get-color(gray-40)
    );
  }
}